export const environment = {
    production: true,
    API_URL: 'https://admin.paco.app/api',
    ONE_SIGNAL_APP_ID: '1a76bb38-4cfe-4890-8ba6-284af9c513de',
    FIREBASE_SENDER_ID: 'FIREBASE_SENDER_ID',
    MAPSKEY: 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg',
    SMARTLOOK_KEY: '4a0168938f55324aa5d5d67e7ad826f6066894f7',
    HELP_URL:'https://ayuda.paco.app/portal/es/kb/soporte-t%C3%A9cnico/aplicaci%C3%B3n-m%C3%B3vil',
    API_KEY_ZOHO_ANDROID:'NDX2fFtcVzsLd4hSb5dqIeihyNfxoGESaahuaGujnOFujny2qTE2fBWXNqmAwVhr',
    API_KEY_ZOHO_IOS:"NDX2fFtcVzsLd4hSb5dqIeihyNfxoGESaahuaGujnOFujny2qTE2fBWXNqmAwVhr",
    ACCESS_TOKEN_ZOHO_ANDROID:'4HUDZh3tu3HhaBJg8%2BCKIm2wmvSYTSHycnGT6xQNNLF9B75%2FWcClwaAohN15Ud37igjavb2MHzy1z5kXvmYWDhncp335oAfWWv9mWgYgpTMk7Q4EDZlQ7A%3D%3D',
    ACCESS_TOKEN_ZOHO_IOS:'5gBV9c0OLzf%2FaErqjZSmuhXML1A4U2168p2EZamlQDXylOU89Xb8zzy6EnZko8YxRqEkhFcWFAPvu0zJgxwXNmznpNjjefzHIYHzpUbctyZFfUeIaKm9ow%3D%3D',
    XML_BASE_URL:'https://admin.paco.app',
    BUGSNAG_API_KEY:'8bbb698c6e080ffebf89835df2d45f31',
    APP_ID_IOS:'6448066447',
    APP_GENERAL_NAME:'La Casa de Toño'
};
